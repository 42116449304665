import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Close, NotificationsNone, Subject } from "@material-ui/icons";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { common } from "../messages";
import PushNotificationOneUser from "./PushNotificationOneUser";

const CheckInWinners = ({ eventAction, isClosed }) => {
  const intl = useIntl();
  const id = eventAction?.id;
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [winners, setWinners] = React.useState(null);
  const [reserve, setReserve] = React.useState(null);
  const [sentFile, setSentFile] = React.useState(null);

  const loadParticipants = useCallback(() => {
    if (bmapi) {
      const params = { eventAction: id };
      startLoading();
      bmapi
        .getCheckinWinners(params)
        .then((resp) => {
          setWinners(resp?.winners || []);
          setReserve(resp?.reserve || []);
          setSentFile(resp?.sent_file);
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(() => stopLoading());
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading, id]);

  useEffect(() => {
    loadParticipants();
  }, [loadParticipants]);

  const onUpdate = (isReserve) => (anonymId, contacted, assigned, notes) => {
    const data = {
      event_action_id: id,
      sent_file: sentFile,
      anonym_id: anonymId,
      is_reserve: isReserve,
      contacted: contacted || false,
      assigned: assigned || false,
      notes: notes || "",
    };
    startLoading();
    return bmapi.updateCheckinWinnerAssignment(data).finally(() => {
      stopLoading();
    });
  };

  if (winners === null) {
    return <Typography>{intl.formatMessage(common.noElement)}</Typography>;
  }

  return (
    <Box mt={2}>
      <Typography>Vincitori</Typography>
      <CheckInWinnersTable
        winners={winners}
        onUpdate={onUpdate(false)}
        isClosed={isClosed}
      />
      <Typography>Riserve</Typography>
      <CheckInWinnersTable
        winners={reserve}
        onUpdate={onUpdate(true)}
        isClosed={isClosed}
      />
    </Box>
  );
};

const CheckInWinnersTable = ({ winners, onUpdate, isClosed }) => {
  const intl = useIntl();
  const [states, setStates] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pushDialogOpen, setPushDialogOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState("");
  const [activeAnonymId, setActiveAnonymId] = React.useState(null);

  useEffect(() => {
    const initial = {};
    winners?.forEach((d) => {
      const id = d.winner_data?.anonym_id;
      if (id) {
        initial[id] = {
          contacted: d.winner_data.contacted,
          assigned: d.winner_data.assigned,
          notes: d.winner_data.notes || "",
        };
      }
    });
    setStates(initial);
  }, [winners]);

  const handleCheckboxChange = (anonymId, field) => async (event) => {
    const value = event.target.checked;
    const current = states[anonymId] || {};

    const newContacted =
      field === "contacted"
        ? value
        : field === "assigned" && value
        ? true // if assigned is being checked, also check contacted
        : current.contacted;

    const newAssigned = field === "assigned" ? value : current.assigned;

    const updatedData = {
      contacted: newContacted,
      assigned: newAssigned,
      notes: current.notes,
    };

    try {
      await onUpdate(
        anonymId,
        updatedData.contacted,
        updatedData.assigned,
        updatedData.notes
      );
      setStates((prev) => ({
        ...prev,
        [anonymId]: {
          ...prev[anonymId],
          contacted: updatedData.contacted,
          assigned: updatedData.assigned,
        },
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenDialog = (anonymId) => {
    setActiveAnonymId(anonymId);
    setDialogValue(states[anonymId]?.notes || "");
    setDialogOpen(true);
  };

  const handleDialogConfirm = async () => {
    const current = states[activeAnonymId] || {};
    const updated = {
      ...current,
      notes: dialogValue,
    };
    try {
      await onUpdate(
        activeAnonymId,
        updated.contacted,
        updated.assigned,
        updated.notes
      );
      setStates((prev) => ({
        ...prev,
        [activeAnonymId]: updated,
      }));
      setDialogOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box mt={2}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage(common.email)}</TableCell>
              <TableCell>{intl.formatMessage(common.name)}</TableCell>
              <TableCell>{intl.formatMessage(common.lastName)}</TableCell>
              <TableCell>{intl.formatMessage(common.mobile)}</TableCell>
              <TableCell>Contattato</TableCell>
              <TableCell>Assegnato</TableCell>
              {!isClosed && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {winners?.map((d, i) => {
              const id = d.winner_data?.anonym_id || `k${i}`;
              return (
                <TableRow key={id} hover>
                  <TableCell>{d.user.email}</TableCell>
                  <TableCell>{d.user.first_name}</TableCell>
                  <TableCell>{d.user.last_name}</TableCell>
                  <TableCell>{d.user.mobile}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={states[id]?.contacted || false}
                      onChange={handleCheckboxChange(id, "contacted")}
                      disabled={isClosed}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={states[id]?.assigned || false}
                      onChange={handleCheckboxChange(id, "assigned")}
                      disabled={isClosed}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleOpenDialog(id)}
                      style={{
                        backgroundColor: states[id]?.notes?.trim()
                          ? "#4caf50"
                          : "transparent", // Green if notes exist
                        color: states[id]?.notes?.trim() ? "white" : "inherit",
                      }}
                    >
                      <Subject />
                    </IconButton>
                  </TableCell>
                  {!isClosed && (
                    <TableCell>
                      <IconButton onClick={() => setPushDialogOpen(d)}>
                        <NotificationsNone />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {(() => {
              const winner = winners?.find(
                (w) => w.winner_data?.anonym_id === activeAnonymId
              );
              return winner
                ? `${winner.user.first_name} ${winner.user.last_name}`
                : "Note";
            })()}
            <IconButton
              aria-label="close"
              onClick={() => setDialogOpen(false)}
              style={{ marginLeft: "auto" }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <textarea
            style={{ width: "100%", minHeight: "100px" }}
            value={dialogValue}
            onChange={(e) => setDialogValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            {intl.formatMessage(common.close)}
          </Button>
          <Button onClick={handleDialogConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!pushDialogOpen?.user?.id}
        onClose={() => setPushDialogOpen(null)}
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {`${pushDialogOpen?.user?.first_name} ${pushDialogOpen?.user?.last_name}`}
            <IconButton
              aria-label="close"
              onClick={() => setPushDialogOpen(null)}
              style={{ marginLeft: "auto" }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PushNotificationOneUser
            userId={pushDialogOpen?.user?.id}
            onPushSent={() => setPushDialogOpen(null)}
            defaultTitle="Estrazione MySarca"
            defaultMessage="Ti preghiamo di venire subito in Direzione (al primo piano a sinistra dell'ufficio postale)"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CheckInWinners;
