import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { common } from "../messages";
import { parseISO, format } from "date-fns";

const CheckInParticipants = ({ id }) => {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [participants, setParticipants] = React.useState(null);

  const loadParticipants = useCallback(() => {
    startLoading();
    if (bmapi) {
      const params = {
        eventAction: id,
      };

      bmapi
        .getCheckinParticipants(params)
        .then((resp) => {
          if (!resp) {
            resp = [];
            setParticipants(resp);
          } else {
            setParticipants(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading, id]);

  useEffect(() => {
    loadParticipants();
  }, [loadParticipants]);

  if (participants === null) {
    return <Typography>{intl.formatMessage(common.noElement)}</Typography>;
  }

  const formatDate = (dt) => {
    if (!dt || dt === "") return "";
    try {
      const parsedDate = parseISO(dt);
      return format(parsedDate, "dd/MM/yyyy HH:mm:ss");
    } catch (_) {
      return dt;
    }
  };

  return (
    <Box mt={0}>
      <Box mt={0}>
        <Typography>{participants?.length} partecipanti</Typography>
      </Box>
      <Box mt={2}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Data e ora check-in</TableCell>
                <TableCell>{intl.formatMessage(common.email)}</TableCell>
                <TableCell>{intl.formatMessage(common.name)}</TableCell>
                <TableCell>{intl.formatMessage(common.lastName)}</TableCell>
                <TableCell>{intl.formatMessage(common.mobile)}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {participants?.map((d) => (
                <TableRow key={d.id} hover>
                  <React.Fragment>
                    <TableCell>{formatDate(d.timestamp)}</TableCell>
                    <TableCell>{d.email}</TableCell>
                    <TableCell>{d.first_name}</TableCell>
                    <TableCell>{d.last_name}</TableCell>
                    <TableCell>{d.mobile}</TableCell>
                  </React.Fragment>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CheckInParticipants;
